import * as React from 'react'
import * as style from '../styles/sequenceeditorv2.module.css'
import GlobalContext from "../context/optionContext";
import { SeqViz } from "seqviz";
import { fetchFeatureTypes, getCommonEnzymes } from '../utils/FeatureUtils';
import { exportComponentAsPNG } from 'react-component-export-image';
import { Checkbox, FormControlLabel, Typography, Button, FormGroup, FormLabel, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import ProteinTable from './proteinTable';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import bionode from 'bionode-seq'
import { getORFs, detectORFs } from '../utils/SeqAnalyser'
import { fetchFeatures } from '../utils/FetchUtils';
import ReplayIcon from '@mui/icons-material/Replay';
import Download from '@mui/icons-material/Download';

const reverseSequence = (seq) => {
    const code = {
        "A": "T",
        "T": "A",
        "C": "G",
        "G": "C",
    }
    // console.log(seq)
    return seq?.replace(/[ACTG]/g, function ($1) { return code[$1] });
}

function atcgiseSeq(seq) {
    const regex = /A|T|C|G/g
    return seq?.toUpperCase().match(regex)?.join("")
}

const featureColors = fetchFeatureTypes(true);
const tabs = ["Sequence Options", "Protein Options"]  // "editSequence", "options"

function SequenceEditorV2(props) {
    return (
        <PageContent {...props} />
    )
}

function Or() {
    return (
        <>
            <div class={style.divholder}>
                {/* <div class={style.connect}></div> */}
                <div class={style.or}>{"OR"}</div>
                {/* <div class={style.connect}></div> */}
            </div>

        </>)
}

function ReadingFrameCheckbox(props) {
    const { readingFrames, setReadingFrames, value } = props
    const rf = parseInt(value)
    return (
        <Checkbox
            size="small"
            defaultChecked
            onChange={() => {
                readingFrames.includes(rf) ? setReadingFrames(readingFrames.filter((v) => v != rf)) : setReadingFrames([...readingFrames, rf])
            }}
        />
    )
}


function PageContent(props) {


    const { theme, language } = React.useContext(GlobalContext);
    const { sequence, setSequence, features, setFeatures } = props;
    let enzymes = features.filter(v => v.legend === "Restriction Sites").map(v => v.name.toLowerCase())


    const componentRef = React.useRef();
    const insertSeqInputRef = React.useRef();
    const searchSeqInputRef = React.useRef();
    const allowedMismatchesInputRef = React.useRef();
    const [showComplement, setShowComplement] = React.useState(true)
    // const [showEnzymes, setShowEnzymes] = React.useState(true)
    const [selectedSubSeq, setSelectedSubSeq] = React.useState({ seq: "", start: null, stop: null, direction: null, ogLength: 0 })
    // const [selectedSubSeqReverse, setSelectedSubSeqReverse] = React.useState("")
    // const [selectedStrand, setSelectedStrand] = React.useState(1)  // 1|-1
    const [tab, setTab] = React.useState(tabs[0])
    const [updating, setUpdating] = React.useState(false)
    const [polypeptides, setPolypeptides] = React.useState([])
    const [minORFLength, setMinORFLength] = React.useState(100)
    // const [readingFrames, setReadingFrames] = React.useState([1, 2, 3, -1, -2, -3])
    const readingFrames = [1, 2, 3, -1, -2, -3]
    // const [searchSeq, setSearchSeq] = React.useState("")
    const [seqvisSearch, setSeqvisSearch] = React.useState({ query: "", mismatch: 0 })
    const [nucleotideBasedColoring, setNucleotideBasedColoring] = React.useState(true)

    function handleSelection(event) {
        // console.log("selection:", event)

        if (event.clockwise) {
            setSelectedSubSeq({ seq: sequence.substring(event.start, event.end), start: event.start, stop: event.end, direction: 1, ogLength: event.length })
            // setSelectedStrand(1)
        } else {
            setSelectedSubSeq({ seq: reverseSequence(sequence.substring(event.start, event.end)), start: event.end, stop: event.start, direction: -1, ogLength: event.length })
            // setSelectedStrand(-1)
        }
    }

    function handleSetPolypeptides(newList) {
        setPolypeptides(newList => {
            return [...polypeptides, newList]
        })
    }

    /**
     *
     * @param {str} dna The DNA sequence to be inserted
     * @param {*} start The start of the insert location
     * @param {*} stop The end of the insert location
     * @param {bool} reverse Whether it is on the reverse strand
     */
    function insertSequence(dna, start, stop, reverse) {
        if (reverse) {
            dna = reverseSequence(dna);
        }
        const insertDiff = dna.length - (stop - start);

        // Insert the DNA
        setSequence(`${sequence.substring(0, start)}${dna}${sequence.substring(stop)}`)

        // Modify the features accordingly

        // If insertion, the start and stop are exactly the same
        if (start === stop) {
            setFeatures(
                features.map((v, i) => {
                    return ({
                        ...v,
                        start: (v.start >= stop) ? v.start + insertDiff : v.start,
                        stop: (v.stop > stop) ? v.stop + insertDiff : v.stop,
                    })
                })
            )
        }
        // If edit/delete
        else {
            setFeatures(
                features
                    .filter((v, i) => {
                        return (dna.length === 0 ? (v.start !== start || v.stop !== stop) : true);
                    })
                    .map((v, i) => {
                        return ({
                            ...v,
                            start: (v.start >= stop) ? v.start + insertDiff : v.start,
                            stop: (v.stop > start) ? v.stop + insertDiff : v.stop,
                        })
                    })
            )
            // setSelectedSubSeq({ ...selectedSubSeq, stop: stop + insertDiff })
        }
    }
    function matchSeqString(fullSeq, query) {
        const start = fullSeq.indexOf(query)
        const end = start + query.length
        return { start: start, end: end, length: query.length, matchedSequence: fullSeq.slice(start, end) }
    }

    async function updateAnnotations(sequence) {
        fetchFeatures(sequence)
            .then((featuresTemp) => {
                setFeatures(featuresTemp)
                setUpdating(false)
            })
    }

    function updateSequence(dna, start, stop, reverse) {
        setUpdating(true)
        if (reverse) dna = reverseSequence(dna);
        setSequence(`${sequence.substring(0, start)}${dna}${sequence.substring(stop)}`)
    }

    React.useEffect(() => {
        // console.log("selectedSubSeq:", selectedSubSeq)
        // console.log("readingFrames:", readingFrames)
        // console.log("featuresUseEffect:", features)
        // console.log("featuresUseEffect-enzymes:", features.filter(v => v.legend === "Restriction Sites"))
        // console.log("polypeptides", polypeptides.length, polypeptides)
        enzymes = features.filter(v => v.legend === "Restriction Sites").map(v => v.name.toLowerCase())
    }, [selectedSubSeq, sequence, readingFrames, polypeptides, features])

    React.useEffect(() => {
        updateAnnotations(sequence)
    }, [sequence])


    return (
        <div class={style.sequenceEditorWrapper}>
            <div class={style.inputsWrapper}>
                <div class={style.optionTabs}>{
                    tabs.map((v, i) => <div class={`${style.option} ${tab === v && style.select}`}
                        key={i} onClick={() => setTab(v)}>{v}</div>)}
                </div>
                {tab === tabs[0] && <>
                    <FormControlLabel
                        style={{ paddingLeft: '10px' }}
                        label="Show Reverse Strand"
                        control={<Checkbox
                            checked={showComplement}
                            onChange={() => {
                                setShowComplement(!showComplement)
                                // console.log(showComplement)
                            }} />}
                    />
                    <FormControlLabel
                        style={{ paddingLeft: '10px' }}
                        label="Apply Nucleotide-Based Color Scheme"
                        control={<Checkbox
                            checked={nucleotideBasedColoring}
                            onChange={() => {
                                setNucleotideBasedColoring(!nucleotideBasedColoring)
                                // console.log(showComplement)
                            }} />}
                    />

                    {/* <div class={style.downloadHolder}>
                        <IconButton
                            onClick={() => {
                                exportComponentAsPNG(componentRef)
                            }}
                            edge={false}
                        >
                            {<DownloadIcon />}
                        </IconButton>
                        <Typography >{"Download Current Sequence View as PNG Image"}</Typography>
                    </div> */}

                    <TextField
                        helperText="Select a subsequence from the sequence map on the right to edit here. To select, either drag the cursor across the sequence in the direction of your desired strand, or click on an annotation."
                        label={`Edit: ${selectedSubSeq.direction === 1 ? "Forward" : "Reverse"} Strand`}
                        multiline
                        InputProps={{
                            style: {
                                fontFamily: "Consolas, 'Courier New', Courier, monospace",
                                color: theme['--text'],
                                fontSize: "0.9rem",
                                backgroundColor: 'white',
                            }
                        }}
                        rows={5}
                        value={selectedSubSeq.seq}
                        onChange={(e) => {
                            // console.log(e)
                            setSelectedSubSeq({ ...selectedSubSeq, seq: atcgiseSeq(e.target.value) })
                        }
                        }
                    />
                    <div class={style.alignHoriz}>
                        <IconButton
                            onClick={() => {
                                // selectedSubSeq.direction === 1 ? setSelectedSubSeq({ ...selectedSubSeq, direction: -1 }) : setSelectedSubSeq({ ...selectedSubSeq, direction: 1 })
                                setSelectedSubSeq({ ...selectedSubSeq, direction: -selectedSubSeq.direction, seq: reverseSequence(selectedSubSeq.seq) })
                                // console.log("selectedStrand:", selectedSubSeq.direction)
                            }}
                            edge="end"
                        >
                            {<SwapVertIcon />}
                        </IconButton>
                        {"Swap Strands"}
                    </div>
                    {/* </div> */}
                    <TextField
                        label={`Preview: ${selectedSubSeq.direction === 1 ? "Reverse" : "Forward"} Strand`}
                        multiline
                        disabled
                        focused
                        color="secondary"
                        InputProps={{
                            style: {
                                fontFamily: "Consolas, 'Courier New', Courier, monospace",
                                // color: theme['--text'],
                                fontSize: "0.9rem",
                                // fontWeight: 400,
                                backgroundColor: '#e1dddd',
                            }
                        }}
                        sx={{ color: 'black' }}
                        rows={5}
                        value={reverseSequence(selectedSubSeq.seq)}
                    />
                    {/* <div class={style.reversePreview} style={{ 'width': '100%' }}>
                        {reverseSequence(selectedSubSeq)}
                    </div> */}
                    <div class={style.flexRow}>
                        <button class={style.addFeatureButton} style={{ marginTop: '10px', marginRight: '3px', width: '35%' }}
                            onClick={() => {
                                if (selectedSubSeq.seq.length === selectedSubSeq.ogLength) {
                                    // console.log("selectedSubSeq:ApplyBtn:", selectedSubSeq)
                                    // insertSequence(selectedSubSeq.seq, selectedSubSeq.start, selectedSubSeq.stop, selectedSubSeq.direction === -1)
                                    updateSequence(selectedSubSeq.seq, selectedSubSeq.start, selectedSubSeq.stop, selectedSubSeq.direction === -1)
                                } else {
                                    alert("Length of modified subsequence is different from originally selected subsequence. This button is only for modifying existing nucleotides. For inserting new nucleotides use the box at the bottom and press 'insert before' or 'insert after'.")
                                }
                            }}
                        >Apply Changes</button>
                        <button class={style.addFeatureButton} style={{ marginTop: '10px', marginRight: '3px', width: '35%', backgroundColor: 'red' }}
                            onClick={() => {
                                // console.log("features:", features)
                                if (window.confirm("Are you sure you want to delete the selected subsequence?")) {
                                    // insertSequence("", selectedSubSeq.start, selectedSubSeq.stop, selectedSubSeq.direction === -1)
                                    updateSequence("", selectedSubSeq.start, selectedSubSeq.stop, selectedSubSeq.direction === -1)
                                    // console.log("features:", features)
                                    // setSelectedSubSeq({ seq: "", start: null, stop: null, direction: null })
                                }
                            }}
                        >Delete Subsequence</button>
                        <CircularProgress size='1.5em' sx={{ visibility: updating ? "visible" : "hidden", width: '7%', margin: '18px 5px 0px 5px' }} />
                        <Button
                            style={{ fontWeight: 'bold', width: '23%', marginTop: '10px' }}
                            onClick={() => {
                                setSelectedSubSeq({ seq: "", start: null, stop: null, direction: null })
                                // setSelectedStrand(1)
                            }}
                        >Clear</Button>
                    </div>

                    <Or />
                    <TextField
                        helperText="Paste sequence here to insert it before or after the selected segment from above. Non-ACTG inputs will be ignored."
                        label="Insert Sequence Before/After Selected"
                        disabled={selectedSubSeq.seq === ""}
                        multiline
                        InputProps={{
                            style: {
                                fontFamily: "Consolas, 'Courier New', Courier, monospace",
                                color: theme['--text'],
                                fontSize: "0.9rem",
                                backgroundColor: 'white',
                            }
                        }}
                        rows={5}
                        inputRef={insertSeqInputRef}
                    />
                    <div class={style.flexRow} style={{ justifyContent: 'center' }}>
                        <Button
                            onClick={() => {
                                // console.log(atcgiseSeq(insertSeqInputRef.current.value))
                                updateSequence(atcgiseSeq(insertSeqInputRef.current.value), selectedSubSeq.start, selectedSubSeq.start, selectedSubSeq.direction === -1)
                            }}
                        ><KeyboardDoubleArrowLeftIcon />Insert Before</Button>
                        <Button
                            style={{ fontWeight: 'bold', width: '25%' }}
                            onClick={() => {
                                insertSeqInputRef.current.value = ""
                                // setSelectedSubSeq({ seq: "", start: null, stop: null, direction: null })
                                // setSelectedStrand(1)
                            }}
                        >Clear</Button>
                        <Button
                            onClick={() => {
                                // console.log(atcgiseSeq(insertSeqInputRef.current.value))
                                updateSequence(atcgiseSeq(insertSeqInputRef.current.value), selectedSubSeq.stop, selectedSubSeq.stop, selectedSubSeq.direction === -1)
                            }}
                        >Insert After<KeyboardDoubleArrowRightIcon /></Button>
                    </div>
                </>}

                {tab === tabs[1] && <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            sx={{ width: '45%' }}
                            type="number"
                            label="Minimum length"
                            value={minORFLength || 0}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            onChange={(event) => {
                                if (parseInt(event.target.value) >= 0) {
                                    setMinORFLength(parseInt(event.target.value))
                                }
                            }}
                        />
                        <Typography>To detect ORFs, ATG is used as the start codon and TAA, TAG, TGA as the stop codons.</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button class={style.addFeatureButton} style={{ margin: '10px' }}
                            onClick={() => {
                                if (polypeptides.length <= 0) {
                                    let fwdORFs = detectORFs(sequence, 1, minORFLength)
                                    let revORFs = detectORFs(bionode.reverseComplement(sequence), -1, minORFLength)
                                    let allORFs = [...fwdORFs, ...revORFs]

                                    setPolypeptides(allORFs)




                                    // for (let i = 0; i < readingFrames.length; i++) {
                                    //     let oneRF = []
                                    //     if (readingFrames[i] > 0) {
                                    //         console.log("ORFSeq:", sequence.substring(i + 1, sequence.length))
                                    //         oneRF = detectORFs(sequence.substring(i + 1, sequence.length), 1, readingFrames[i])
                                    //     } else if (readingFrames[i] < 0) {
                                    //         console.log("ORFSeqRev:", bionode.reverseComplement(sequence).substring(i + 1, sequence.length))
                                    //         oneRF = detectORFs(bionode.reverseComplement(sequence).substring(i - 1, sequence.length), -1, readingFrames[i])
                                    //     }
                                    //     console.log("ORFs:", i, oneRF)
                                    //     allORFs.push(oneRF)
                                    // setPolypeptides(polypeptides => [...polypeptides, oneRF])
                                    // handleSetPolypeptides(oneRF)
                                    // setTimeout(() => {
                                    //     setPolypeptides([...polypeptides, ...oneRF])
                                    // }, 0);
                                    // }
                                    // console.log("allORFs:", allORFs)
                                } else {
                                    alert("ORFs are already generated. Click on the 'Clear' button to remove them first.")
                                }
                            }}
                        >Translate ORFs</button>
                        <button class={style.addFeatureButton} style={{ margin: '10px' }}
                            disabled={polypeptides.length <= 0}
                            onClick={() => {
                                setPolypeptides([])
                            }}
                        >Clear</button>
                    </div>
                    <ProteinTable polypeptides={polypeptides} setPolypeptides={setPolypeptides} style={{ maxHeight: '100%' }} />
                </>}
            </div>




            <div class={style.flexCol} style={{ width: '67em' }}>
                <div class={style.flexRow} style={{ justifyContent: "space-between" }}>
                    <div>
                        <TextField
                            style={{ margin: '5px 5px', width: '18em' }}
                            type="text"
                            label="Search sequence (wildcards supported)"
                            variant="standard"
                            size="small"
                            // palceholder="ATCGATCG"
                            // value={searchSeq}
                            inputRef={searchSeqInputRef}
                            onChange={(e) => {

                                // if (e.target.value <= sequence.length && e.target.value >= 0) setGoToBPNum(e.target.value)
                            }}
                            inputProps={{
                                minLength: 0,
                                maxLength: 20,
                                // pattern: /A|T|C|G/g
                            }}
                        />
                        <TextField
                            style={{ margin: '5px 5px', width: '15em' }}
                            type="number"
                            label="Number of mismatches allowed"
                            variant="standard"
                            size="small"
                            // value={searchSeq}
                            inputRef={allowedMismatchesInputRef}
                            onChange={(e) => {
                                // if (e.target.value <= sequence.length && e.target.value >= 0) setGoToBPNum(e.target.value)
                            }}
                            InputProps={{
                                inputProps: { min: 0, max: 20 }
                            }}
                        />
                        <Button
                            // disabled={simpleView}
                            sx={{ padding: '7px', fontSize: '0.8em' }}
                            variant="contained"
                            // disabled={seqvisSearch.query.length <= 0}
                            style={{ marginTop: '12px', verticalAlign: 'center' }}
                            onClick={() => {
                                // const querySeq = atcgiseSeq(searchSeqInputRef.current.value)
                                const querySeq = searchSeqInputRef.current.value
                                const mismatches = parseInt(allowedMismatchesInputRef.current.value)
                                if (querySeq && querySeq.length > 1 && mismatches != undefined) {
                                    setSeqvisSearch({ query: querySeq, mismatch: mismatches })
                                    // } else if (mismatches === querySeq.length) {
                                    //     alert("Number of allowed mismatches must be at most 3 less than the length of the search sequence.")
                                } else {
                                    alert("Please enter a valid query sequence (must be longer than 1 nucleotide) and the number of allowed mismatches before searching.")
                                }
                            }}
                        >Search Site</Button>
                        <Button
                            sx={{ padding: '7px', fontSize: '0.8em', marginLeft: '5px' }}
                            variant="contained"
                            color="error"
                            disabled={!seqvisSearch.query}
                            style={{ marginTop: '12px', verticalAlign: 'center' }}
                            onClick={() => {
                                setSeqvisSearch({ query: "", mismatch: 0 })
                            }}
                        >Clear highlights</Button>
                    </div>
                    <div style={{ marginTop: '12px', verticalAlign: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{ padding: '6px', fontSize: '0.8em' }}
                            onClick={() => {
                                exportComponentAsPNG(componentRef)
                            }}
                        >Download View<Download />
                        </Button>
                    </div>
                    <div style={{ marginTop: '12px', verticalAlign: 'center' }}>
                        <Button
                            sx={{ padding: '6px', fontSize: '0.8em' }}
                            variant="contained"
                            title="Refresh Sequence Annotations" color="secondary"
                            disabled={updating}
                            onClick={() => {
                                setUpdating(true)
                                updateAnnotations(sequence)
                            }}
                        >Refresh <ReplayIcon /></Button>
                    </div>
                </div>
                <div style={{ height: '100%', border: '1px solid', borderRadius: '5px' }} >
                    <SeqViz
                        ref={componentRef}
                        viewer="linear"
                        name="test-plasmid-name"
                        seq={sequence}
                        annotations={features.filter(v => v.legend != "Restriction Sites").map((v, i) => {
                            let color = featureColors[v.legend]
                            return { name: v.name, start: v.start - 1, end: v.stop - 1, direction: v.strand, color: color }
                        })}
                        enzymes={enzymes}
                        translations={polypeptides.filter(v => v.visible)}
                        showComplement={showComplement}
                        zoom={{ linear: 81.74 }}
                        // search={{ query: "AGATCTAT", mismatch: 1 }}
                        search={seqvisSearch}
                        bpColors={nucleotideBasedColoring ? { "A": "green", "T": "red", "G": "blue", "C": "orange" } : null}
                        // bpColors={{ 1: "red", 2: 'red', 3: 'red', 4: 'blue', 5: 'blue', 6: 'blue' }}
                        // selection={{
                        //     start: selectedSubSeq.start || 0,
                        //     end: selectedSubSeq.stop || 0,
                        //     clockwise: selectedSubSeq.direction === 1
                        // }}
                        onSelection={(event) => {
                            handleSelection(event)
                            // console.log("seqvizSelection:", event)
                            // console.log("SELECION!!!")
                        }}
                        highlights={[]}
                    />
                </div>
            </div>
        </div>
    )
}


export default SequenceEditorV2

// extracted by mini-css-extract-plugin
export var annotate = "sequenceupload-module--annotate--4e5bb";
export var annotateButton = "sequenceupload-module--annotateButton--a4b6b";
export var box = "sequenceupload-module--box--bccba";
export var buttonUpload = "sequenceupload-module--buttonUpload--840ed";
export var buttonholder = "sequenceupload-module--buttonholder--998ec";
export var connect = "sequenceupload-module--connect--acca8";
export var divholder = "sequenceupload-module--divholder--3b07b";
export var downloadBox = "sequenceupload-module--downloadBox--8fbe1";
export var exampleButton = "sequenceupload-module--exampleButton--059ab";
export var exampleSelected = "sequenceupload-module--exampleSelected--c28c9";
export var inputSeq = "sequenceupload-module--inputSeq--f751c";
export var linkref = "sequenceupload-module--linkref--f976c";
export var or = "sequenceupload-module--or--fa5fd";
export var textbox = "sequenceupload-module--textbox--dfd07";
export function getORFs(sequence, options) {
    const startCodons = options.startCodons  // array
    const stopCodons = options.stopCodons  // array
    const minLength = options.minLength  // number
    const direction = options.direction

    let orfs = {
        "1": {
            "orfs": []   // [{start, end, seq}]
        },
        "2": {
            "orfs": []
        },
        "3": {
            "orfs": []
        }
    }

    let allCodons = {
        "1": {
            "offset": 0,
            "indexMarkers": {
                "startIndices": [],  // step 2A
                "stopIndices": []  // step 2B
            },
            "orfs": {
                "3SplitIndices": [], // step 3A
                "ORFs": []  // step 3B
            },
            "3Splits": []  // step 1
        },
        "2": {
            "offset": 1,
            "indexMarkers": {
                "startIndices": [],
                "stopIndices": []
            },
            "orfs": {
                "3SplitIndices": [],
                "ORFs": []
            },
            "3Splits": []
        },
        "3": {
            "offset": 2,
            "indexMarkers": {
                "startIndices": [],
                "stopIndices": []
            },
            "orfs": {
                "3SplitIndices": [],
                "ORFs": []
            },
            "3Splits": []
        }
    }
    for (let i = 1; i < 4; i++) {
        allCodons[i]["3Splits"] = sequence.substring(i - 1, sequence.length).match(/.{1,3}/g)
    }
    // console.log(allCodons)

    Object.keys(allCodons).forEach(key => {
        // console.log("key", key)
        // console.log("allCodons[key]", allCodons[key])
        let codons = allCodons[key]["3Splits"]
        // console.log("codons", codons)
        let startIndices = [], stopIndices = [];
        for (let i = 0; i < codons.length; i++) {
            // console.log("here", codons[i])
            if (startCodons.includes(codons[i])) {
                startIndices.push(i)
            }
            if (stopCodons.includes(codons[i])) {
                stopIndices.push(i)
            }
        }
        allCodons[key]["indexMarkers"] = { "startIndices": startIndices, "stopIndices": stopIndices }
        // console.log(key, startIndices, stopIndices)

        // console.log("test", allCodons[key].orfs)
        if (startIndices.length > 0 && stopIndices.length > 0) {
            for (const startIndex of allCodons[key]["indexMarkers"]["startIndices"]) {
                let found = false
                for (const stopIndex of allCodons[key]["indexMarkers"]["stopIndices"]) {
                    // console.log(startIndex, stopIndex)
                    if (startIndex < stopIndex) {
                        // console.log(allCodons[key]["orfs"])
                        allCodons[key]["orfs"]["3SplitIndices"].push({ "startIndex": startIndex, "stopIndex": stopIndex })
                        found = true
                        break;
                    }
                }
                if (!found) {
                    allCodons[key]["orfs"]["3SplitIndices"].push({ "startIndex": startIndex, "stopIndex": allCodons[key]["indexMarkers"]["stopIndices"][0] })
                }
            }
        }

        for (const indexCouple of allCodons[key]["orfs"]["3SplitIndices"]) {
            if (indexCouple.startIndex < indexCouple.stopIndex) {
                let orf = allCodons[key]["3Splits"].slice(indexCouple.startIndex, indexCouple.stopIndex).join("")
                allCodons[key]["orfs"]["ORFs"].push(orf)
            } else {
                let orf = allCodons[key]["3Splits"].slice(indexCouple.startIndex).join("")
                orf += allCodons[key]["3Splits"].slice(0, indexCouple.stopIndex).join("")
                allCodons[key]["orfs"]["ORFs"].push(orf)
            }
        }

        for (const indexCouple of allCodons[key]["orfs"]["3SplitIndices"]) {
            let start = ((indexCouple.startIndex) * 3) + allCodons[key]["offset"]
            let stop = ((indexCouple.stopIndex + 1) * 3) + allCodons[key]["offset"]   // +1 to include the stop codon as well
            // let seq = sequence.substring()
            orfs[key]["orfs"].push({ start: start, end: stop, direction: direction })
        }
    })

    // // fixing indices if negative strand
    // if (direction === -1) {
    //     Object.keys(orfs).forEach(key => {
    //         for (const indexCouple of orfs[key]["orfs"]) {
    //             indexCouple.start = sequence.length - indexCouple.start - 1
    //             indexCouple.end = sequence.length - indexCouple.end - 1
    //             // console.log("sus:", indexCouple.start, indexCouple.end)
    //         }
    //     })
    // }

    console.log("before", orfs)
    console.log("minLength:", minLength)
    // filtering minORFLength
    Object.keys(orfs).forEach(key => {
        // orfs[key]["orfs"].map((indexCouple) => indexCouple.length = )
        orfs[key]["orfs"] = orfs[key]["orfs"].filter((indexCouple) => (Math.abs((indexCouple.start - indexCouple.end)) >= minLength))
    })



    console.log(allCodons)
    console.log("after", orfs)
    return orfs
}

export function detectORFs(dna, direction, minORFLength) {
    const orfs = [];

    for (let i = 0; i < dna.length; i++) {
        if (dna.substring(i, i + 3) === 'ATG') {
            let j = i + 3;
            while (true) {
                if (dna.substring(j, j + 3) === 'TAA' || dna.substring(j, j + 3) === 'TAG' || dna.substring(j, j + 3) === 'TGA') {
                    const length = Math.abs(i - j)
                    if (length >= minORFLength) orfs.push({ start: i, end: j + 3, length: length, direction: direction, visible: true });
                    break;
                }
                j += 3;
                if (j >= dna.length - 2) {
                    break;
                    // if (orfs.length <= 0) break;
                    // If we reach the end of the string without finding a stop codon,
                    // and there are no previously detected stop codons then stop searching, else
                    // start searching again from the beginning of the string
                    // j = 0;
                }
            }
        }
    }

    for (let i = 0; i < orfs.length; i++) {
        let hit = orfs[i]
        if (direction === -1) {
            const start = dna.length - hit.start - 1
            const end = dna.length - hit.end - 1
            orfs[i].start = end + 1
            orfs[i].end = start + 3
        }
    }

    return orfs;
}

// dense table template from https://mui.com/material-ui/react-table/

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';

export default function ProteinTable(props) {
    const { polypeptides, setPolypeptides } = props
    const [tableData, setTableData] = React.useState(polypeptides)

    React.useEffect(() => {
        console.log("polypeptides", polypeptides)
        console.log("tableData: ", tableData)
        setTableData(polypeptides)
    }, [tableData])

    return (
        <TableContainer component={Paper} sx={{ maxHeight: '90%' }}>
            <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: '800' }} align="center"></TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="center">#</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="center">Length</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="center">Start</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="center">Stop</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="center">Strand</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {polypeptides && polypeptides.map((row, index) => (
                        <TableRow
                            key={row["#_hit"]}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" width="auto"><IconButton onClick={() => {
                                // row['visible'] = !row['visible']
                                let modifiedPolypeptides = [...polypeptides]
                                modifiedPolypeptides[index]['visible'] = !modifiedPolypeptides[index]['visible']
                                setPolypeptides(modifiedPolypeptides)
                            }}>{row['visible'] ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton></TableCell>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{row['length']}</TableCell>
                            <TableCell align="center">{row['start']}</TableCell>
                            <TableCell align="center">{row['end']}</TableCell>
                            <TableCell align="center">{row['direction']}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// extracted by mini-css-extract-plugin
export var addFeatureButton = "sequenceeditorv2-module--addFeatureButton--dedab";
export var divholder = "sequenceeditorv2-module--divholder--2aa80";
export var downloadHolder = "sequenceeditorv2-module--downloadHolder--dae0a";
export var flexCol = "sequenceeditorv2-module--flexCol--0dc06";
export var flexRow = "sequenceeditorv2-module--flexRow--53683";
export var inputsWrapper = "sequenceeditorv2-module--inputsWrapper--3cbf8";
export var option = "sequenceeditorv2-module--option--bf25b";
export var optionTabs = "sequenceeditorv2-module--optionTabs--a9891";
export var or = "sequenceeditorv2-module--or--55e34";
export var refreshSpinner = "sequenceeditorv2-module--refreshSpinner--81c59";
export var reversePreview = "sequenceeditorv2-module--reversePreview--8ce59";
export var select = "sequenceeditorv2-module--select--71e71";
export var sequenceEditorWrapper = "sequenceeditorv2-module--sequenceEditorWrapper--624ea";
import React, { useState, useEffect } from 'react';
import { checkBLASTStatus } from '../utils/FetchUtils';

export default function BlastblastStatus(props) {
    const { task_id, blastStatus, setBlastStatus, blastingNow, setBlastingNow, polling, setPolling, style } = props;
    console.log("from-BlastblastStatus-task_id", task_id)
    // const  = useState(null);
    // const [jobResult, setJobResult] = useState(null);

    useEffect(() => {
        if (blastingNow) {
            const interval = setInterval(async () => {
                if (blastStatus != "SUCCESS") {
                    const data = await checkBLASTStatus(task_id)
                    // const data = await response.json();
                    data.status = data.status === "PENDING" ? "PROCESSING" : data.status;
                    setBlastStatus(data.status);

                    if (data.status === 'SUCCESS') {
                        // setJobResult(data.result);
                        setPolling(false);
                        // setBlastingNow(false);
                    }
                } else {
                    setPolling(false)
                }
            }, 3000);

            // Clean up the interval when the component is unmounted or when polling stops
            return () => clearInterval(interval);
        }
    }, [task_id, polling, blastStatus]);

    return (
        <div style={{ ...style }}>
            <h3>Job Status: {blastStatus}</h3>
        </div>
    );
};


// dense table template from https://mui.com/material-ui/react-table/

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";

const seqTableCellStyle = {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    whiteSpace: 'normal',
    width: '20em',
    height: '5em',
    maxHeight: '5em',
    fontFamily: 'monospace',
    overflowWrap: 'scroll'
}


export default function CodonOptimizationTable(props) {
    const { coFeatures, coFeatureSelectedIndex } = props
    const [tableData, setTableData] = React.useState(coFeatures)

    React.useEffect(() => {
        console.log("coFeatures: ", coFeatures)
        console.log("tableData-coFeatures: ", tableData)
        setTableData(coFeatures)
    }, [tableData])

    function sequenceForTable(sequence, type, length, lineLength) {
        let seqString = ""
        const codonLength = 3
        if (type === "nucleotide") {
            for (let i = 0; i < length; i++) {
                if (i != 0 && i % lineLength === 0) seqString += '\n'
                if (i != 0 && i % codonLength === 0 && i % lineLength != 0) seqString += ' '
                seqString += sequence[i]
            }
            const returnString = sequence.length > length ? `${seqString}\n...` : seqString
            return returnString

        } else if (type === "protein") {
            let tempSeq = ""
            for (let i = 0; i < sequence.length; i++) {
                tempSeq += "-"
                tempSeq += sequence[i]
                tempSeq += "-"
                if (i % 2 === 0) tempSeq += '\n'
            }
            console.log("tempSeq", tempSeq)
            seqString = tempSeq

            const returnString = sequence.length > length ? `${seqString.substring(0, length + 5)}\n...` : seqString
            return returnString
        }

    }

    const coCols = ["#", "Start", "Stop", "Target Species", "Original Sequence", "Optimized Sequence", "Tail Nucleotide(s)", "Download FASTA"]
    return (
        <TableContainer component={Paper} sx={{ maxHeight: '18em', maxWidth: '30em' }}>
            <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell sx={{ fontWeight: '800' }} align="right"></TableCell> */}
                        <TableCell sx={{ fontWeight: '800' }} align="right">#</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="right">Start</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="right">Stop</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="right">Target Species</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="right">Original Sequence</TableCell>
                        <TableCell sx={{ fontWeight: '800' }} align="right">Optimized Sequence</TableCell>
                        {/* <TableCell sx={{ fontWeight: '800' }} align="right">Protein Sequence</TableCell> */}
                        <TableCell sx={{ fontWeight: '800' }} align="right">Tail Nucleotide(s)</TableCell>
                        {/* <TableCell sx={{ fontWeight: '800' }} align="right">Download FASTA</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {coFeatures && coFeatures.map((row, index) => (
                        <TableRow
                            key={row["#_hit"]}
                            selected={coFeatureSelectedIndex === index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {/* <TableCell align="right">
                                <IconButton
                                    onClick={() => {
                                        console.log("CLICKEDD")
                                    }}
                                    >
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell> */}
                            <TableCell align="right">{index + 1}</TableCell>
                            {/* <TableCell component="th" scope="row">{row.subjectTitle}</TableCell> */}
                            <TableCell align="right">{row['start']}</TableCell>
                            <TableCell align="right">{row['stop']}</TableCell>
                            <TableCell align="right">{row['targetSpecies']}</TableCell>
                            <TableCell sx={seqTableCellStyle} align="left">{sequenceForTable(row['ogSeq'], "nucleotide", Math.min(30, row['optimizedSeq'].length), Math.min(9, row['optimizedSeq'].length))}</TableCell>
                            <TableCell sx={seqTableCellStyle} align="left">{sequenceForTable(row['optimizedSeq'], "nucleotide", Math.min(30, row['optimizedSeq'].length), Math.min(9, row['optimizedSeq'].length))}</TableCell>
                            {/* <TableCell sx={seqTableCellStyle} align="left">{sequenceForTable(row['proteinSeq'], "protein", 30, 9)}</TableCell> */}
                            <TableCell align="right">{row['strayNucleotides'] === "" ? "n/a" : row['strayNucleotides']}</TableCell>
                            {/* <TableCell align="right"><DownloadIcon/></TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

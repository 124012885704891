import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Editor from "../components/editor";
import { stripInput, getFastaName } from '../utils/FeatureUtils';
import * as style from '../styles/index.module.css'

import GlobalContext from "../context/optionContext";

import BackgroundDrawing from "../components/background";

import { fetchFeatures, fetchSequence } from "../utils/FetchUtils";
import SequenceUpload from "../components/sequenceupload";
import { Paper } from '@mui/material';


export default function IndexPage(props) {
  return (
    <Layout>
      <PageContent {...props} />
    </Layout>
  )
}



function PageContent(props) {
  const { theme, setTheme, language, setLanguage } = React.useContext(GlobalContext);
  const [annotate, setAnnotate] = React.useState(false);
  const [example, setExample] = React.useState(-1);
  const [exampleLoading, setExampleLoading] = React.useState(-1);
  const [sequence, setSequence] = React.useState("");
  const [plasmidName, setPlasmidName] = React.useState("Plasmid");
  // const [loading, setLoading] = React.useState(false)
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [data, setData] = React.useState([]);
  const { location } = props
  const [startTab, setStartTab] = React.useState(0);
  const [annotating, setAnnotating] = React.useState(false)
  const [showEditor, setShowEditor] = React.useState(false)

  React.useEffect(() => {
    /**
     * Only used if the user clicked in from the search page
     * Makes it so it automatically annotates and scrolls to plasmid editor
     */
    if (props?.location?.state?.nameSearch) {
      setStartTab(2);
      setPlasmidName(props.location.state.nameSearch);
      fetchSequence(props.location.state.nameSearch)
        .then(data => {
          // check for codonOptimization and targetSpecies
          console.log("STATES: ", props.location.state.codonOptimization, props.location.state.targetSpecies)
          setSequence(stripInput(data, true));
          annotateSequenceLoad(props.location.state.nameSearch, data,
            props.location.state.codonOptimization,
            props.location.state.targetSpecies
            // props.location.state.setAnnotating
          );
        }
        )
        .catch(err => {
          console.log(err);
        }
        );
    }

  }, [location, firstLoad])

  /**
   * Annotates the provided DNA sequence
   * @param  {str} seq The sequence provided by the search page
   */
  const annotateSequenceLoad = async (name, seq) => {
    {
      setAnnotating(true)
      setPlasmidName(name);
      // If FASTA, set the map title to the header
      if (seq[0] === ">") {
        setPlasmidName(getFastaName(seq) || "Plasmid");
      }
      seq = stripInput(seq, true)
      setSequence(seq)
      fetchFeatures(seq)
        .then(featureTemp => {
          setData(featureTemp);
          // setAnnotating(false)
          // setShowEditor(true)
          // document.getElementById("annotate").scrollIntoView();
          scrollToEditor()
        })
        .catch(err => console.log(err))
    }
  }

  function scrollToEditor() {
    setAnnotating(false)
    setShowEditor(true)
    // document.getElementById("annotate").scrollIntoView()
    window.scrollTo(0, document.getElementById('annotate').offsetTop);
  }

  return (
    <div style={{ ...theme }}>
      <Seo title="Home" />
      <BackgroundDrawing />
      <div style={{ width: "100px", height: "150px" }}></div>
      <p class={style.indexbody}>
        <div class={style.logodiv}><p class={style.logotitle}>PlasMapper <span class={style.logosmall}>3.0</span></p>
          <p class={style.catchphrase}>{language.CATCHPHRASE}</p></div>
        <SequenceUpload annotate={annotateSequenceLoad} name={plasmidName} annotating={annotating} />
        <div style={{ marginTop: `5em`, marginBottom: `6em`, marginRight: `min(10em, 15%)`, marginLeft: `min(10em, 15%)`, textAlign: 'left' }}>
          <Paper sx={{ padding: '10px' }}>
            <b>Welcome to PlasMapper 3.0!</b>
            <p>
              PlasMapper 3.0 is a web server that allows users to generate, edit, annotate and interactively visualize publication quality plasmid maps. Plasmid maps are used ubiquitously in molecular biology and they provide a simple, visual approach to plan, design, share and publish critical information about cloning experiments. PlasMapper 3.0 has provided access to new and extended plasmid databases from which users can pick a wide variety of plasmid. Additionally, it offers an option of automated codon optimization and BLAST sequence alignment. PlasMapper 3.0 supports a variety of input formats including raw sequence, FASTA files and its own PlasMapper JSON format.
            </p>
            <b>Please cite: </b>
            <p>
              1. PlasMapper 3.0:
              <br></br>
              <em>
                Wishart, D. S., Ren, L., Leong-Sit, J., Saha, S., Grant, J. R., Stothard, P., Singh, U., Kropielnicki, A., Oler, E., Peters, H., Gautam, V. (2023), PlasMapper 3.0 - A Web Server for Generating, Editing, Annotating and Visualizing Publication Quality Plasmid Maps, Nucleic Acids Research, https://doi.org/10.1093/NAR/GKAD276
              </em>
            </p>
            <p>2. <a href="http://wishart.biology.ualberta.ca/PlasMapper/"> PlasMapper 2.0:</a> <br></br><a href="https://doi.org/10.1093/NAR/GKH410"><em>Dong, X., Stothard, P., Forsythe, I. J., & Wishart, D. S. (2004). PlasMapper: a web server for drawing and auto-annotating plasmid maps. Nucleic Acids Research, 32(Web Server Issue), W660. https://doi.org/10.1093/NAR/GKH410</em></a></p>
          </Paper>
        </div>
        {showEditor && <div id="annotate">
          <Editor data={data} name={plasmidName} sequence={sequence} setSequence={setSequence}></Editor>
        </div>}

      </p>
    </div>
  )
}


import * as React from "react"
import { fetchFeatureTypes } from '../utils/FeatureUtils';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import OptionAccordion from "./accordion";
import GlobalContext from "../context/optionContext";
import '../styles/cgview.css';
import InputAdornment from '@mui/material/InputAdornment';
// import SequenceEditor from "./sequenceEditor";
import SequenceEditorV2 from "./sequenceEditorV2";
import PosterViewModal from "./posterViewModal";
import * as style from "../styles/editor.module.css"
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PanToolIcon from '@mui/icons-material/PanTool';
import PaletteIcon from '@mui/icons-material/Palette';
import { Button, LinearProgress, Typography, Divider, Modal, Box, FormHelperText } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { Context } from 'svgcanvas'
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { getDiamondBlast, getNcbiBlast, submitBLASTJob } from "../utils/FetchUtils";
import BlastHitsTable from "./blastHitsTable"
import CodonOptimizationTable from "./codonOptimizationTable"
import { runCodonOptimization } from "../utils/FetchUtils"
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import BlastJobStatus from "./blastJobStatus";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LogarithmicSlider from "./logarithmicSlider";

const CGV = require('cgview');


const featureColors = fetchFeatureTypes(true);
const tabs = ["All Features", "+ New Feature", "Restriction Sites", "Image Options", "BLAST", "Codon Optimization"]
// const blastColsDiamond = ["#", "Gene Title", "Percent Identity", "Length", "E-Value", "Bit Score"]
const blastColsNCBI = ["#", "Gene Title", "Raw Score", "Bit Score", "E-Value", "Plasmid Start", "Plasmid Stop", "Percent Identity"]
const allTargetSpecies = ["E. Coli (Escherichia coli str. K-12 substr. MG1655)", "Bakers Yeast (Saccharomyces cerevisiae S288C)", "Caenorhabditis elegans", "Fruit Fly (Drosophila melanogaster)", "Thale Cress (Arabidopsis thaliana)", "Human (Homo sapiens)", "Mouse (Mus musculus)", "Rat (Rattus norvegicus)", "Zebrafish (Danio rerio)", "African Clawed Frog (Xenopus laevis)"]
// const initialDownloadHeight = 500;
// const maxDownload = 10000;
// const minDownload = 500;
const maxFileSize = 1000000;

const orfTrack = {
    name: "ORFs",
    position: 'both',
    dataType: 'feature',
    dataMethod: 'sequence',
    dataKeys: 'orfs',
    dataOptions: {
        start: 'ATG',
        stop: 'TAA,TAG,TGA',
        minORFLength: 90
    }
}

const gcContentTrack = {
    name: 'GC Content',
    position: 'inside',
    dataType: 'feature',
    dataMethod: 'sequence',
    // e.g. In this example, GC Content plot will be extracted from the sequence.
    dataKeys: 'gc-content',
    // By default, the step and window values are calculated based on the sequence length,
    // but they can be overridden here.
    dataOptions: {
        step: 1,
        window: 100
    }
}

const defaultTrack = {
    "name": "Features",
    "position": 'both',
    "dataType": 'feature',
    "dataMethod": 'source',
    "dataKeys": 'json-feature'
}

const optimizationTrack = {
    "name": "Codon Optimization",
    "position": "inside",
    "dataType": "feature",
    "dataMethod": "source",
    "dataKeys": "json-feature"
}


const featureData = fetchFeatureTypes();

var cgvHandle = null;

function TouchSnackBar(props) {
    const { isAddStart, isAddStop, setIsAddStart, setIsAddStop } = props
    return (
        <Snackbar open={isAddStart || isAddStop}
            message="Click on the sequence backbone (turnt red) on the plasmid map to set the position."
            onClose={() => {
                setIsAddStart(false)
                setIsAddStop(false)
            }}>
        </Snackbar>
    )
}

function Editor(props) {
    const targetRef = React.useRef();

    const { theme, setTheme, language, setLanguage } = React.useContext(GlobalContext);
    // const { isEdit } = props;
    const { sequence, data, name, setSequence } = props;

    const [tab, setTab] = React.useState(0);
    const [initial, setInitial] = React.useState(true);
    const [localData, setLocalData] = React.useState([]);
    const [addName, setAddName] = React.useState("New Feature");
    const [addStart, setAddStart] = React.useState(0);
    const [addStop, setAddStop] = React.useState(100);
    const [addCategory, setAddCategory] = React.useState("User-defined");
    const [cgvFormat, setCgvFormat] = React.useState("circular");
    const [cgvDownload, setCgvDownload] = React.useState(false);
    const [cgvDownloadSVG, setCgvDownloadSVG] = React.useState(false);
    const [cgvReset, setCgvReset] = React.useState(() => { });
    const [cgvZoomIn, setCgvZoomIn] = React.useState(() => { });
    const [cgvZoomOut, setCgvZoomOut] = React.useState(() => { });
    const [cgvMoveLeft, setCgvMoveLeft] = React.useState(() => { });
    const [cgvMoveRight, setCgvMoveRight] = React.useState(() => { });
    const [cgvToggleLabels, setCgvToggleLabels] = React.useState(() => { });
    const [cgvInvertColors, setCgvInvertColors] = React.useState(() => { });
    // const [showOrf, setShowOrf] = React.useState(false);
    // const [showGCContent, setShowGCContent] = React.useState(false);
    const [tracks, setTracks] = React.useState([defaultTrack]);
    const [showLegend, setShowLegend] = React.useState(true);
    const [panel, setPanel] = React.useState(false);
    const [plasmidName, setPlasmidName] = React.useState(name || "Plasmid");
    const [isAddStart, setIsAddStart] = React.useState(false);
    const [isAddStop, setIsAddStop] = React.useState(false);
    const [legendItems, setLegendItems] = React.useState(featureData.map((v, i) => { return { name: v.display, swatchColor: v.color, bwColor: v.bwColor, decoration: v.decoration } }));
    const [isBw, setIsBw] = React.useState(false);
    const [initialInvertColor, setInitialInvertColor] = React.useState("initial") // initial || notInitial

    // const [codonOptimization, setCodonOptimization] = React.useState(false)
    const [targetSpecies, setTargetSpecies] = React.useState(allTargetSpecies[0])
    // const [codonOptimized, setCodonOptimized] = React.useState([false, null])  // where [1] = targetSpecies
    const [coStart, setCoStart] = React.useState(0)
    const [coStop, setCoStop] = React.useState(0)
    const [coFeatures, setCoFeatures] = React.useState([])  // [{start:number, stop:number, targetSpecies:str, ogSeq:str, optimizedSeq:str, strayNucleotides:str}]
    const [coFeatureSelectedIndex, setCoFeatureSelectedIndex] = React.useState(-1)
    // const [downloadHeight, setDownloadHeight] = React.useState(2000);
    // const [downloadWidth, setDownloadWidth] = React.useState(3000);

    // const [width, setWidth] = React.useState(1000);
    // const [height, setHeight] = React.useState(initialDownloadHeight);

    const [fileWarning, setFileWarning] = React.useState(false);
    const [blastHits, setBlastHits] = React.useState(null)
    const [blastingNow, setBlastingNow] = React.useState(false)
    const [blastUserEmail, setBlastUserEmail] = React.useState("")

    const [goToBPNum, setGoToBPNum] = React.useState(0)
    // const [posterView, setPosterView] = React.useState(false)
    const [posterView, setPosterView] = React.useState(false)
    const [isAddStartStopDisabled, setIsAddStartStopDisabled] = React.useState(false)
    // console.log("sv:", simpleView)
    const [zoomLock, setZoomLock] = React.useState(true)
    const [hoverBPNum, setHoverBPNum] = React.useState(null)
    const [blastTaskId, setBlastTaskId] = React.useState(null)
    const [blastStatus, setBlastStatus] = React.useState(null)
    const [blastFeature, setBlastFeature] = React.useState(null)
    const [blastDB, setBlastDB] = React.useState("swissprot")
    const [blastEvalue, setBlastEvalue] = React.useState(0.001)
    const [polling, setPolling] = React.useState(false);


    let allLegendItems = localData.map(w => w.legend);
    let allLegendItemsSet = new Set(allLegendItems);
    const json = {
        "cgview": {
            "version": "1.1.0",
            "name": plasmidName,
            "captions": [
                {
                    "backgroundColor": "rgba(255,255,255,0.4)",
                    "font": "Arial,bold,15",
                    "fontColor": "#000000ff",
                    "name": plasmidName + `\n${sequence.length} bp`,
                    "position": "middle-center",
                    "textAlignment": "center",
                    // "on": "canvas",
                    "anchor": "middle-center"
                }],
            "sequence": {
                seq: sequence,
                "color": "rgba(255,255,255,1)"
            },
            "backbone": {
                "color": `${isAddStart || isAddStop ? 'rgba(252,3,36,1)' : 'rgba(0,0,0,1)'}`,
                "meta": {
                    basepair: hoverBPNum
                }
            },
            "features": localData,
            "legend": {
                // Maps the preset feature data from above into the legend
                "items": legendItems.filter(v => allLegendItemsSet.has(v.name)),
                "visible": showLegend
            },
            "tracks": tracks
        }
    }

    /**
     * Updates the feature at a specified index with the specified values
     * @param  {int} index The index of the feature to update
     * @param  {obj} val The attribute(s) to be updated in the feature
     */
    function handleFeatureUpdate(index, val) {
        setLocalData(
            localData.map(
                (v, i) => {
                    return i === index ? { ...v, ...val } : v
                }
            )
        );
    }

    /**
     * Updates the visibility of a specified restriction site
     * @param  {str} enzyme The name of the restriction site
     * @param  {bool} visible The visibility to change it to
     */
    function handleRestrictionUpdate(enzyme, visible) {
        setLocalData(
            localData.map(
                (v, i) => {
                    return v.legend === "Restriction Sites" && v.name === enzyme ? { ...v, visible: visible } : v
                }
            )
        );
    }

    function handleClickOption(option) {
        setPanel(panel === option ? false : option)
        setTab(0)
        document.getElementById(`accordion-panel-${option}`).scrollIntoView({ block: 'nearest' })
    }

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    React.useEffect(() => {
        // If it is currently getting fed a new plasmid
        if (initial === true) {
            setLocalData(data);
            setLegendItems(featureData.map((v, i) => { return { name: v.display, swatchColor: v.color, bwColor: v.bwColor, decoration: v.decoration } }))
        }

    }, [data])

    React.useEffect(() => {
        // Change the name based on the inputted prop
        setPlasmidName(name);
    }, [name])

    React.useEffect(() => {

        // If we are currently on the CGV tab, draw CGView
        const mapHeight = document.getElementById("my-viewer").offsetHeight - 5
        const mapWidth = document.getElementById("my-viewer").offsetWidth - 5
        const cgv = new CGV.Viewer('#my-viewer', {
            height: mapHeight,
            width: mapWidth,
        });

        cgv.io.viewer.externals.SVGContext = Context  // for cgv.io.downloadSVG()
        cgv.annotation.labelPlacementFast = 'angled'
        cgv.annotation.labelPlacementFull = 'angled'

        cgv.on('mousemove', (event) => {
            if (event.elementType === "backbone") {
                event.score = event.bp
                console.log(event)
                setGoToBPNum(event.bp)
            }
        })

        cgv.on('click', (event) => {
            if (event.elementType === 'feature' && event.element.source === 'json-feature' && event.element?.tags.length) {
                // console.log(event.element.tags[0]);
                let curIndex = event.element.tags[0];
                // handleFeatureUpdate(curIndex, {...localData[curIndex], name:"test"})
                handleClickOption(curIndex);
                // document.getElementById()
            }
            else if (event.elementType === 'backbone' && isAddStart) {
                setIsAddStart(false);
                setAddStart(event.bp);
            }
            else if (event.elementType === 'backbone' && isAddStop) {
                setIsAddStop(false);
                setAddStop(event.bp);
            }
        });
        cgv.io.loadJSON(json);
        const myNode = document.getElementById("my-viewer");
        myNode.removeChild(myNode.childNodes[0]);
        console.log("caption:", cgv.captions())
        cgv.captions()[0].update({ 'on': 'map' })
        cgv.settings.update({ format: cgvFormat });

        if (isBw == true) {
            setTimeout(function () {
                let indexBuffer = tracks.includes(gcContentTrack) ? 2 : 1
                var orfLegendItem = cgv.legend.items()[cgv.legend.items().length - indexBuffer];
                orfLegendItem["swatchColor"] = "#001";
                // console.log(cgv.captions())
                cgv.captions()[0].update({ fontColor: 'black' })
                cgv.legend.updateItems(orfLegendItem);
                cgv.draw();
            }, 500)
        } else {
            cgv.draw();
        }

        // console.log("annotations:", cgv.annotation)
        cgv.annotation.update({ color: 'black', font: 'Arial, plain, 11.4' })
        cgv.ruler.update({ visible: !posterView })
        cgv.backbone.update({ visible: !posterView })
        cgv.settings.update({ showShading: !posterView })
        cgv.dividers.slot.update({ visible: !posterView })
        // console.log("sv:", simpleView)

        if (posterView) {
            setIsAddStartStopDisabled(true)
        } else {
            setIsAddStartStopDisabled(false)
        }

        if (zoomLock) cgv._zoom.filter((event) => event.type != 'wheel')
        if (!zoomLock) cgv._zoom.filter((event) => event)

        cgvHandle = cgv;

    }, [localData, cgvReset, cgvZoomIn, cgvZoomOut, cgvMoveLeft, cgvMoveRight, cgvToggleLabels, cgvInvertColors, cgvFormat, panel, isAddStart, isAddStop, plasmidName, showLegend, legendItems, sequence, tracks, posterView])

    // cgvHandle.on('mousemove', (event) => {
    //     console.log(event)
    // })
    console.log("handle", cgvHandle)

    React.useEffect(() => {
        if (zoomLock) cgvHandle._zoom.filter((event) => event.type != 'wheel')
        if (!zoomLock) cgvHandle._zoom.filter((event) => event)
    }, [zoomLock])

    React.useEffect(() => {
        if (isBw) setLegendItems(legendItems.map((v, i) => { return { ...v, swatchColor: v.bwColor, bwColor: v.swatchColor } }))
        if (!isBw) setLegendItems(featureData.map((v, i) => { return { name: v.display, swatchColor: v.color, bwColor: v.bwColor, decoration: v.decoration } }))
    }, [isBw])

    /**
     * Downloads the current map in JSON format in case the user wants to come back later
     */
    function downloadJSON() {
        let json = {}
        json.name = plasmidName;
        json.sequence = sequence;
        json.features = localData;
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([JSON.stringify(json)], { type: `application/json` }));
        a.download = `PlasMapper3-${plasmidName}.json`;
        a.click();
    }

    function downloadAllFormats(cgvHandle) {
        cgvHandle.io.downloadImage(cgvHandle.width, cgvHandle.height, `PlasMapper3-${plasmidName}.png`)
        cgvHandle.io.downloadSVG(`PlasMapper3-${plasmidName}.svg`)
        downloadJSON()
    }

    async function doBlast(querySeq, queryParams, email, plasmidName, tempFeature) {
        console.log("here1")
        console.log("doBlast:", querySeq, queryParams, email, tempFeature)
        setBlastingNow(true)
        // getNcbiBlastRemote(querySeq)
        // getNcbiBlast(querySeq, email)

        submitBLASTJob(querySeq, queryParams, email, plasmidName, tempFeature)
            .then((data) => {
                console.log("here3")
                // console.log("hits:", data)
                setBlastStatus(data.status === "PENDING" ? "PROCESSING" : data.status)
                setBlastTaskId(data.task_id)
                setBlastHits(data.blastHits)
                // setBlastingNow(false)
            })
    }

    function downloadBlastResults(blastHits) {
        // console.log("downloading blast...")
        let csvData = ""
        // console.log(blastHits)
        Object.keys(blastHits[0]).forEach((key) => {
            csvData += key + ", "
        }); csvData += "\n"

        blastHits.map((hit) => {

            let row = ""
            Object.keys(hit).forEach((key) => {
                let col = hit[key]
                col = col.replace(/,/ig, " -")
                row += col + ", "
            })
            // console.log(row)
            csvData += row + "\n"

        })
        // console.log(csvData)

        const data = csvData
        const filename = `PlasMapper-${plasmidName}-Blast_Results.csv`
        const blob = new Blob([data], { type: 'text/csv' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
            URL.revokeObjectURL(elem)
        }
    }

    function invertColors(cgvHandle) {
        cgvHandle.invertColors()
        // if (initialInvertColor === 'initial') {
        //     const currentBackColor = cgvHandle.legend.backgroundColor._rgbaString
        //     console.log('currentBackground: ', currentBackColor)
        //     cgvHandle.legend.backgroundColor._rgbaString = currentBackColor === 'rgba(255,255,255,1)' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'
        // } else {
        //     setInitialInvertColor('notInitial')
        // }
        // const currentFontColor = cgvHandle.legend.defaultFontColor._rgbaString
        // console.log('currentFont: ', currentFontColor)
        // cgvHandle.legend.defaultFontColor._rgbaString = currentFontColor === 'rgba(255,255,255,1)' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)'

        // console.log('cr: ', cgvHandle.legend.items(0))
        // cgvHandle.draw()
    }

    /**
     * Uploads a JSON file to the current map
     * @param  {file} file The file to upload
     */
    function uploadJSON(file) {
        if (file) {
            if (file.type !== "application/json") {
                setFileWarning("File must be a JSON file");
                return;
            }
            if (file.size > maxFileSize) {
                setFileWarning("File is over 1mb. File may be incorrect.");
                return;
            }
            setFileWarning(false);

            try {
                const fileReader = new FileReader();
                fileReader.readAsText(file, "UTF-8");
                fileReader.onload = file => {
                    try {
                        let uploadedFile = JSON.parse(file.target.result);
                        console.log(uploadedFile);
                        if (Object.keys(uploadedFile).length !== 3 || !uploadedFile.name || !uploadedFile.sequence || !uploadedFile.features) {
                            setFileWarning("File format error. File must be a JSON file with the following keys: name, sequence, features");
                            return;
                        }
                        setLegendItems(featureData.map((v, i) => { return { name: v.display, swatchColor: v.color, bwColor: v.bwColor, decoration: v.decoration } }));
                        setPlasmidName(uploadedFile.name);
                        setSequence(uploadedFile.sequence);
                        setLocalData(uploadedFile.features);
                    }
                    catch (err) {
                        setFileWarning("File is not a valid JSON file");
                        return;
                    }
                }
            }
            catch (err) {
                setFileWarning("File is not a valid JSON file");
                return;
            }
        }
    }

    async function doCodonOptimization(start, stop, targetSpecies) {
        return runCodonOptimization(sequence.substring(start, stop), targetSpecies)
            .then((data) => {
                console.log("DATA", data)
                // console.log("DATA", data.)
                const newOptimization = {
                    start: start,
                    stop: stop,
                    targetSpecies: targetSpecies,
                    ogSeq: data.originalSeq,
                    optimizedSeq: data.res.optimized_sd,
                    strayNucleotides: data.straySeq,
                    proteinSeq: data.res.peptide_seq
                }
                console.log("newOptimization:", newOptimization)
                // setCoFeatures([...coFeatures, newOptimization])
                setCoFeatures([newOptimization])
                setCoFeatureSelectedIndex(0)
                const seqReplacement = newOptimization['optimizedSeq'] + newOptimization['strayNucleotides']
                setSequence(sequence.replace(sequence.substring(start, stop), seqReplacement))
                // console.log("seqReplacement", seqReplacement, seqReplacement.length)
                // console.log("seqOriginal", sequence.substring(start, stop), sequence.substring(start, stop).length)
                // console.log("ogSeq", newOptimization['ogSeq'], newOptimization['ogSeq'].length)
            })
    }

    console.log("localData:", localData)

    return (
        <>
            <h1 class={style.heading}>PlasMapper Editor</h1>
            <div style={{ ...theme }} class={style.editor}>
                <div class={style.cgvEditorWrapper}>
                    <div class={style.options}>
                        {<div class={style.optionTabs}>{
                            tabs.map((v, i) => <div class={`${style.option} ${tab === i && style.select}`}
                                key={i} onClick={() => setTab(i)}>{v}</div>)}
                        </div>}
                        {[
                            <div style={{}}>
                                <OptionAccordion

                                    localData={localData}
                                    handleClickOption={handleClickOption}
                                    panel={panel}
                                    handleFeatureUpdate={handleFeatureUpdate}></OptionAccordion>
                            </div>
                            ,


                            <div class={style.newFeature}>
                                <TextField onChange={(e) => setAddName(e.target.value)}
                                    id="add-name" label="Name" variant="standard"
                                    value={addName}
                                />
                                <TouchSnackBar isAddStart={isAddStart} isAddStop={isAddStop} setIsAddStart={setIsAddStart} setIsAddStop={setIsAddStop} />
                                <TextField onChange={(e) => setAddStart(e.target.value)}
                                    id="add-start" label="Start" variant="standard" type="number"
                                    value={addStart}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    title="Set 'start' position by tapping on the map"
                                                    onClick={() => setIsAddStart(!isAddStart)}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                    disabled={isAddStartStopDisabled}
                                                >
                                                    {<TouchAppIcon color={isAddStart ? 'error' : 'inherit'} />}
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />
                                <TextField onChange={(e) => setAddStop(e.target.value)} id="add-stop" label="Stop"
                                    variant="standard" type="number" value={addStop}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    title="Set 'stop' position by tapping on the map"
                                                    onClick={() => setIsAddStop(!isAddStop)}
                                                    onMouseDown={handleMouseDown}
                                                    edge="end"
                                                    disabled={isAddStartStopDisabled}
                                                >
                                                    {<TouchAppIcon color={isAddStop ? 'error' : 'inherit'} />}
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="category-label">Category</InputLabel>
                                    <Select
                                        labelId="category-label"
                                        id="add-category"
                                        value={addCategory}
                                        label="Category"
                                        size="small"
                                        onChange={(e) => setAddCategory(e.target.value)}
                                    >
                                        {featureData.map((v, i) => { return <MenuItem value={v.display}>{v.display}</MenuItem> })}
                                    </Select>
                                </FormControl>
                                <button
                                    class={style.addFeatureButton}
                                    onClick={() => {
                                        setLocalData([...localData, { name: addName, start: addStop < addStart ? addStop : addStart, stop: addStop < addStart ? addStart : addStop, legend: addCategory, source: "json-feature", tags: localData.length, visible: true, strand: addStop < addStart ? -1 : 1 }])
                                    }}
                                >Add
                                </button>
                            </div>,


                            <div class={style.restrictionGrid} style={{ overflow: 'scroll' }}>
                                {localData.map((v, i) => {
                                    return (
                                        v.legend === "Restriction Sites" && v?.firstSite &&
                                        <div class={v.count === 1 ? "" : style.restrictionInvisible}>
                                            <div>{v.name}</div>
                                            <div>{v.count}</div>
                                            <Checkbox
                                                checked={v.visible}
                                                onChange={(e) => handleRestrictionUpdate(v.name, !v.visible)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>,


                            <div class={style.optionOther} style={{ overflow: 'scroll' }}>
                                <FormControlLabel style={{ marginTop: '0px', marginBotton: '0px' }} control={
                                    <Checkbox
                                        checked={posterView}
                                        onChange={() => {
                                            setPosterView(!posterView)
                                            // setIsAddStart(!simpleView)
                                            // setIsAddStop(!simpleView)
                                            console.log("sv:", posterView)
                                        }}
                                    />
                                } label="Poster View" />
                                {/* <FormControlLabel style={{ marginTop: '0px', marginBotton: '0px' }} control={
                                    <Checkbox
                                        checked={tracks.includes(orfTrack)}
                                        onChange={() => {
                                            if (tracks.includes(orfTrack))
                                                setTracks(tracks.filter(t => t !== orfTrack))
                                            else {
                                                setTracks([...tracks, orfTrack])
                                            }
                                        }}
                                    />
                                } label="Show ORFs (minimum length: 90)" /> */}
                                <FormControlLabel style={{ marginTop: '0px', marginBotton: '0px' }} control={
                                    <Checkbox
                                        checked={tracks.includes(gcContentTrack)}
                                        onChange={() => {
                                            if (tracks.includes(gcContentTrack)) {
                                                setTracks(tracks.filter(t => t !== gcContentTrack))
                                            } else {
                                                setTracks([...tracks, gcContentTrack])
                                            }
                                        }}
                                    />
                                } label="Show GC Content" />
                                <FormControlLabel style={{ marginTop: '0px', marginBotton: '0px' }} control={
                                    <Checkbox
                                        checked={showLegend}
                                        onChange={() => setShowLegend(!showLegend)}
                                    />
                                } label="Show Legend" />
                                <FormControlLabel style={{ marginTop: '0px', marginBotton: '0px' }} control={
                                    <Checkbox
                                        checked={isBw}
                                        onChange={() => setIsBw(!isBw)}
                                    />
                                } label="Greyscale Mode" />
                                <TextField onChange={(e) => setPlasmidName(e.target.value)}
                                    id="add-name" label="Plasmid Title" variant="standard"
                                    value={plasmidName}
                                />
                                {/* <TextField onChange={(e) => {
                                    let newVal = e.target.value;
                                    if(newVal < minDownload){newVal = minDownload}
                                    if(newVal > maxDownload){newVal = maxDownload}
                                    setDownloadHeight(newVal);
                                    // setHeight(width/(downloadWidth/newVal));
                                }}
                            id="add-height" label="Set Download Height" type="number"
                            InputProps={{endAdornment:
                                <InputAdornment position="end">px</InputAdornment>
                                    }}
                            value={downloadHeight}
                        />
                         <TextField onChange={(e) => {
                                    let newVal = e.target.value;
                                    if(newVal < minDownload){newVal = minDownload}
                                    if(newVal > maxDownload){newVal = maxDownload}
                                    setDownloadWidth(newVal);
                                    // setHeight(width/(newVal/downloadHeight));
                                }}
                            id="add-width" label="Set Download Width" type="number"
                            InputProps={{endAdornment:
                                <InputAdornment position="end">px</InputAdornment>
                                    }}
                            value={downloadWidth}
                        /> */}
                                {/* <Button onClick={() => { setPosterView(true) }}>Open Poster View</Button>
                                <PosterViewModal
                                    posterView={posterView}
                                    setPosterView={setPosterView}
                                    seq={sequence}
                                    name={plasmidName}
                                    viewer="circular"
                                    enzymes={localData.filter(v => v.legend === "Restriction Sites").filter(v => v.visible).map(v => v.name.toLowerCase())}
                                    annotations={localData.filter(v => v.legend != "Restriction Sites").filter(v => v.visible).map((v, i) => {
                                        let color = featureColors[v.legend]
                                        return { name: v.name, start: v.start, end: v.stop, direction: v.strand, color: color }
                                    })} /> */}
                                <div class={style.downloadHolder}>
                                    {/* <Typography >{"Download"}</Typography> */}
                                    <IconButton
                                        onClick={() => {
                                            cgvHandle.io.downloadImage(cgvHandle.width, cgvHandle.height, `PlasMapper3-${plasmidName}.png`)
                                        }
                                        }
                                        onMouseDown={handleMouseDown}
                                        edge={false}
                                    >
                                        {<DownloadIcon />}
                                    </IconButton>
                                    <Typography >{"Download Map as PNG Image"}</Typography>
                                </div>
                                <div class={style.downloadHolder}>
                                    {/* <Typography >{"Download"}</Typography> */}
                                    <IconButton
                                        onClick={() => cgvHandle.io.downloadSVG(`PlasMapper3-${plasmidName}.svg`)}
                                        onMouseDown={handleMouseDown}
                                        edge={false}
                                    >
                                        {<DownloadIcon />}
                                    </IconButton>
                                    <Typography >{"Download Map as SVG Image"}</Typography>
                                </div>
                                <div class={style.downloadHolder}>
                                    {/* <Typography >{"Download"}</Typography> */}
                                    <IconButton
                                        onClick={() => downloadJSON()}
                                        onMouseDown={handleMouseDown}
                                        edge={false}
                                    >
                                        {<DownloadIcon />}
                                    </IconButton>
                                    <Typography >{"Download Map as JSON"}</Typography>
                                </div>

                                <div class={style.downloadHolder}>
                                    <Typography >{"Upload JSON"}</Typography>
                                    <input
                                        type="file"
                                        accept="application/json"
                                        onChange={(e) => uploadJSON(e.target.files[0])}
                                        class={style.uploadHolder}
                                    />
                                </div>
                                {fileWarning && <Typography sx={{ color: "red" }}>{fileWarning}</Typography>}
                            </div>,

                            <div class={style.optionBLAST}>
                                <FormControl required fullWidth style={{ marginTop: '10px', marginBottom: '15px' }}>
                                    <InputLabel id="select-feature-for-blast-label">Feature Sequence to BLAST</InputLabel>
                                    <Select
                                        autoWidth={true}
                                        helperText="Select a feature to BLAST"
                                        labelId="select-feature-for-blast-label"
                                        id="blast-feature-label-simple"
                                        value={blastFeature}
                                        label="Feature Sequence to BLAST"
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            setBlastFeature(e.target.value)
                                        }}
                                    >
                                        {localData.filter((i) => i.legend != "Restriction Sites").map((v, i) => {
                                            return (
                                                <MenuItem
                                                    value={`${i}@@${v.name}@@${v.legend}@@${v.start}@@${v.stop}@@${v.strand}`} key={`${v}-blast-features-select`}>
                                                    {`${v.name} (${v.legend}, ${v.start}-${v.stop}, ${v.strand})`}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText>Please select a gene/feature from the map as the query sequence for BLASTx. If your desired sequence range is not here, please create a new feature with the desired sequence range and select that feature from here. All 3 reading frames of the feature's strand will be queried.</FormHelperText>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" sx={{ textAlign: 'left', fontSize: '1.25em' }}>Select Database:</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="database"
                                        name="database"
                                        value={blastDB}
                                        onChange={(e) => {
                                            setBlastDB(e.target.value)
                                            // console.log("blastDB:", blastDB)
                                        }}
                                    >
                                        <FormControlLabel value="swissprot" control={<Radio size="small" />} label="UniProtKB/Swiss-Prot (upto x100 faster)" />
                                        <FormControlLabel value="ncbi-nr" disabled control={<Radio size="small" />} label="NCBI Non-Redundant (nr) [COMING SOON]" />
                                    </RadioGroup>
                                </FormControl>
                                <LogarithmicSlider blastEvalue={blastEvalue} setBlastEvalue={setBlastEvalue} />
                                <TextField required id="outlined-basic" label="Email" variant="outlined" inputProps={{ type: 'email' }}
                                    helperText={`Please enter your email to receive your BLASTx results. Make sure the email is correct before clicking 'Submit BLAST'. A confirmation email will be sent right after you submit your BLAST query to confirm that your submission has been enqueued. Once your submission is BLASTed, you will receive another email with the results.`}
                                    sx={{ marginTop: '15px' }}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        setBlastUserEmail(e.target.value)
                                    }}
                                />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '20px' }}>
                                    <button class={style.addFeatureButton}
                                        onClick={() => {

                                            let tempFeature = {
                                                "name": blastFeature.split('@@')[1],
                                                "legend": blastFeature.split('@@')[2],
                                                "start": blastFeature.split('@@')[3] - 1,
                                                "stop": blastFeature.split('@@')[4],
                                                "strand": blastFeature.split('@@')[5]
                                            }
                                            let queryParams = {
                                                "db": blastDB,
                                                "evalue": blastEvalue,

                                            }
                                            console.log("featureData:", tempFeature)
                                            console.log("blastFeature:", blastFeature)
                                            doBlast(sequence.substring(blastFeature.split('@@')[3] - 1, blastFeature.split('@@')[4]), queryParams, blastUserEmail, plasmidName, tempFeature)
                                        }}
                                    >Submit BLAST</button>
                                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton
                                            onClick={() => downloadBlastResults(blastHits)}
                                            disabled={blastHits === null}>
                                            <DownloadIcon />
                                        </IconButton>
                                        <Typography style={{ paddingTop: '7px' }}>Download Results</Typography>
                                    </div> */}
                                </div>
                                {(blastingNow || polling) && <BlastJobStatus task_id={blastTaskId} blastStatus={blastStatus} setBlastStatus={setBlastStatus} blastingNow={blastingNow} setBlastingNow={setBlastingNow} polling={polling} setPolling={setPolling} style={{ margin: '1em 1em 1em 1em', border: '1px solid', borderRadius: '3px' }} />}
                                {polling && <LinearProgress color="secondary" style={{ margin: "10px 0px" }} />}
                                {/* <BlastHitsTable style={{}} blastHits={blastHits}></BlastHitsTable> */}
                            </div>,

                            <div class={style.optionBLAST}>
                                {/* <FormControlLabel
                                control={<Checkbox defaultChecked={false} />}
                                 label="Apply Codon Optimization"
                                 onChange={() => { setCodonOptimization(!codonOptimization) }} /> */}
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <TextField
                                        sx={{ margin: '3px', width: '50%' }}
                                        onChange={(e) => {
                                            const targetVal = parseInt(e.target.value)
                                            if (targetVal <= sequence.length && targetVal >= 0) setCoStart(targetVal)
                                        }}
                                        id="co-start" label="Start" variant="standard" type="number"
                                        value={coStart}
                                        InputProps={{
                                            inputProps: { min: 0, max: sequence.length }
                                        }}
                                    />
                                    <TextField
                                        sx={{ margin: '3px', width: '50%' }}
                                        onChange={(e) => {
                                            const targetVal = parseInt(e.target.value)
                                            if (targetVal <= sequence.length && targetVal >= 0) setCoStop(targetVal)
                                        }}
                                        id="co-stop" label="Stop" variant="standard" type="number"
                                        value={coStop}
                                        InputProps={{
                                            inputProps: { min: 0, max: sequence.length }
                                        }}
                                    />
                                </div>
                                <FormControl required fullWidth style={{ marginTop: '10px', marginBottom: '15px' }}>
                                    <InputLabel id="select-target-species-for-codon-opt">Target Species</InputLabel>
                                    <Select
                                        labelId="select-target-species-for-codon-opt"
                                        id="target-sp-label-simple"
                                        value={targetSpecies}
                                        label="Target Species"
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            setTargetSpecies(e.target.value)
                                        }}
                                    >
                                        {allTargetSpecies.map((v, i) => {
                                            return (
                                                <MenuItem
                                                    value={`${v}`} key={v}>
                                                    {v}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>

                                </FormControl>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button class={style.addFeatureButton}
                                        style={{ margin: '0px 5px' }}
                                        onClick={() => {
                                            // if ((coStart > 0 && coStop > 0) && (coStop >= coStart + 9)) doCodonOptimization(sequence, coStart, coStop, targetSpecies)
                                            if ((coStart > 0) && (coStop > 0)) {
                                                (Math.abs(coStop) > Math.abs(coStart + 20)) ? doCodonOptimization(coStart, coStop, targetSpecies) : alert("There must be at least 21 nucleotides for codon optimization.")
                                            } else {
                                                alert('"Start" and "Stop" must not be at positions 0. The position index starts from 1.')
                                            }
                                            // setTracks([...tracks, optimizationTrack])
                                        }}
                                    >Apply Optimization</button>
                                    <button class={style.addFeatureButton}
                                        style={{ margin: '0px 5px' }}
                                        // disabled={!coFeatures}
                                        onClick={() => {
                                            if (coFeatures.length > 0) setSequence(sequence.replace(sequence.substring(coStart, coStop), coFeatures[0]['ogSeq']))
                                            setCoFeatureSelectedIndex(-1)
                                        }}
                                    >Reset Sequence
                                    </button>
                                </div>
                                <CodonOptimizationTable coFeatures={coFeatures} coFeatureSelectedIndex={coFeatureSelectedIndex}></CodonOptimizationTable>
                            </div>
                        ].map((v, i) => tab === i && v)
                        }
                    </div>

                    <div class={style.drawing} ref={targetRef}>
                        <div class={style.svgwrap}>
                            <>
                                <div className={style.cgvButtons}>
                                    <span class={style.cgvbuttonholder}>
                                        <ZoomInIcon sx={{ height: '0.75em', width: '0.75em', marginLeft: '5px' }}></ZoomInIcon>
                                        <Typography sx={{ fontSize: 'small', marginLeft: '2px' }}>Zoom by scrolling</Typography>
                                        <PanToolIcon sx={{ height: '0.75em', width: '0.75em', marginLeft: '10px' }}></PanToolIcon>
                                        <Typography sx={{ fontSize: 'small', marginLeft: '3px' }}>Drag to change position</Typography>
                                        <PaletteIcon sx={{ height: '0.75em', width: '0.75em', marginLeft: '10px' }}></PaletteIcon>
                                        <Typography sx={{ fontSize: 'small', marginLeft: '3px' }}>Click legend to change colours</Typography>
                                        <PriorityHighIcon sx={{ height: '0.75em', width: '0.75em', marginLeft: '10px' }}></PriorityHighIcon>
                                        <Typography sx={{ fontSize: 'small', marginLeft: '0px' }}>Please zoom in for all the features to be visible</Typography>
                                    </span>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div className={style.cgvButtons}>
                                            <div title="Lock/unlock zoom by scroll" style={{ paddingTop: '2px', cursor: 'pointer' }} onClick={() => setZoomLock(!zoomLock)}>
                                                {zoomLock ? <LockIcon /> : <LockOpenIcon />}
                                            </div>
                                            <div onClick={() => cgvHandle.zoomIn()} class="cgv-btn" id="btn-zoom-in" title="Zoom In"></div>
                                            <div onClick={() => cgvHandle.zoomOut()} class="cgv-btn" id="btn-zoom-out" title="Zoom Out"></div>
                                            <div onClick={() => downloadAllFormats(cgvHandle)} class="cgv-btn" id="btn-download" title="Download Map PNG, SVG and JSON"></div>
                                            <div onClick={() => setCgvFormat((cgvFormat == 'circular') ? 'linear' : 'circular')} class="cgv-btn" id="btn-toggle-format" title="Toggle Linear/Circular Format"></div>
                                            {/* <Divider orientation="vertical" variant="fullWidth" flexItem light sx={{marginRigth: '2px'}}/> */}
                                            <div onClick={() => cgvHandle.moveLeft()} class="cgv-btn" id="btn-move-left" title="Move Left/Counterclockwise"></div>
                                            <div onClick={() => cgvHandle.moveRight()} class="cgv-btn" id="btn-move-right" title="Move Right/Clockwise"></div>
                                            <div onClick={() => { cgvHandle.annotation.update({ visible: !cgvHandle.annotation.visible }); cgvHandle.draw(); }} class="cgv-btn" id="btn-toggle-labels" title="Toggle Labels"></div>
                                            <div onClick={() => cgvHandle.reset()} class="cgv-btn" id="btn-reset" title="Reset Map Position"></div>
                                            {/* <div onClick={() => invertColors(cgvHandle)} class="cgv-btn" id="btn-invert-colors" title="Invert Map Colors"></div> */}
                                        </div>
                                        <div>
                                            <Button
                                                sx={{ padding: '2px', fontSize: '0.8em' }}
                                                variant="contained"
                                                disabled={posterView}
                                                color="primary"
                                                onClick={() => {
                                                    if (goToBPNum > 0) {
                                                        cgvHandle.zoomTo(goToBPNum, cgvHandle.backbone.adjustedThickness / cgvHandle.backbone.bpThicknessAddition)
                                                    } else {
                                                        alert('Position index must not be at position 0. The position index starts from 1.')
                                                    }
                                                }}
                                            >Go to base number</Button>
                                            <TextField
                                                style={{ margin: '0px 5px' }}
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                value={goToBPNum}
                                                onChange={(e) => {
                                                    if (e.target.value <= sequence.length && e.target.value >= 0) setGoToBPNum(e.target.value)
                                                }}
                                                InputProps={{
                                                    inputProps: { min: 0, max: sequence.length }
                                                }}
                                            />
                                            <a style={{ color: 'grey' }}>eg. 1236</a>
                                        </div>
                                    </div>
                                </div>
                                <div id='my-viewer' style={{ width: '100%', height: '100%', border: '1px solid', borderRadius: '5px' }}>
                                    <div style={{ border: '1px solid', borderRadius: '5px' }}>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                <div class={style.sequenceEditorHolder}>
                    <SequenceEditorV2 sequence={sequence} setSequence={setSequence} features={localData} setFeatures={setLocalData} />
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'row', height: '38em' }}>
                    <SequenceEditor sequence={sequence} setSequence={setSequence} features={localData} setFeatures={setLocalData} plasmidName={plasmidName} />
                </div> */}
            </div>
        </>
    )
}

export default Editor

import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';


export default function LogarithmicSlider(props) {
    const { blastEvalue, setBlastEvalue } = props;

    const minLogValue = Math.log10(1e-100);
    const maxLogValue = Math.log10(10);

    const handleChange = (event, newblastEvalue) => {
        setBlastEvalue(Math.pow(10, newblastEvalue));
    };

    return (
        <div sx={{ display: 'flex', flexDirection: 'col' }}>
            <div sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography id="logarithmic-slider" sx={{ fontSize: '1.2em', textAlign: 'left', color: '#00000099' }} gutterBottom>
                    E-Value:
                    <TextField
                        size="small"
                        type="number"
                        sx={{ marginLeft: '10px' }}
                        value={blastEvalue.toExponential(3)}
                        onChange={(e) => setBlastEvalue(parseFloat(e.target.value))}
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: 1,
                                step: 0.001,
                            }
                        }}
                    />

                    {/* <Typography sx={{ border: '1px solid black', borderRadius: '5px', padding: '3px', background: '#dedede' }}>  </Typography> */}
                </Typography>
            </div>
            <Slider
                sx={{ color: '#00000099', border: '1px solid #00000099', marginTop: '15px', marginBotton: '15px' }}
                value={Math.log10(blastEvalue)}
                valueLabelDisplay='auto'
                color="secondary"
                min={minLogValue}
                max={maxLogValue}
                step={0.001}
                onChange={handleChange}
                valueLabelFormat={(x) => Math.pow(10, x).toExponential(3)}
                aria-labelledby="logarithmic-slider"
                getAriaValueText={(value) => `Value: ${Math.pow(10, value).toExponential(3)}`}
            />
        </div>
    );
}

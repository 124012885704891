import * as React from "react"
import * as style from "../styles/sequenceupload.module.css"
import { Link, navigate } from "gatsby";
import LinkIcon from '@mui/icons-material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import { fetchSamplePlasmids } from "../utils/SamplePlasmids";
import GlobalContext from "../context/optionContext";
import { FileUploader } from "react-drag-drop-files";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button } from '@mui/material'
import { Tooltip, Zoom, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuItem, Select, InputLabel, FormControl, FormControlLabel, Checkbox, LinearProgress, CircularProgress } from '@mui/material';
const samplePlasmids = fetchSamplePlasmids();

const WARNING_LENGTH = 200000;
const MAX_LENGTH = 500000;
const allInputTypes = {
    "paste-seq": "Paste nucleotide sequence",
    "upload-seq": "Upload nucleotide sequence file",
    "browse-database": "Browse plasmid database"
}

function CustomLinkIcon() {
    return (<LinkIcon sx={{ transform: "rotate(-30deg)", "vertical-align": "middle", "margin-left": "5px" }} />)
}

function Or() {
    return (
        <>
            <div class={style.divholder}>
                {/* <div class={style.connect}></div> */}
                <div class={style.or}>{"OR"}</div>
                {/* <div class={style.connect}></div> */}
            </div>

        </>)
}

function ExampleButton(props) {
    const { selected, name, expressionSystem, btnColor } = props;
    return (
        <Tooltip title={expressionSystem} arrow>
            <Button {...props}
                sx={{ width: '8.1rem', height: '2.75rem', textTransform: 'none', backgroundColor: selected ? "#9181e3" : btnColor, color: '#white' }}
                variant="contained"
            // color={selected ? "info" : "warning"}
            >{name}</Button>
        </Tooltip>
    )
}

function SequenceUpload(props) {
    const { annotate, annotating } = props;
    const { theme, language } = React.useContext(GlobalContext);
    const [file, setFile] = React.useState("");
    const [sample, setSample] = React.useState(-1);
    const [sequence, setSequence] = React.useState("");
    const [plasmidName, setPlasmidName] = React.useState("Plasmid");
    const [plasmidMetadata, setPlasmidMetadata] = React.useState("");
    const [warning, setWarning] = React.useState(false);
    const [inputType, setInputType] = React.useState(["paste-seq", 0])  // inputType[1] === 1 only when a file has been uploaded. used for showing uploaded file's sequence in the paste sequence input
    // const [annotating, setAnnotating] = React.useState(false)
    const [isCorrectInput, setIsCorrectInput] = React.useState(false)
    const MUItheme = createTheme({
        palette: {
            primary: {
                main: theme['--plasmid3'],
            },
            secondary: {
                main: theme['--plasmid3'],
            },
        },
    });

    function handleFile(e) {
        const fileReader = new FileReader();
        fileReader.readAsText(e, "UTF-8");
        fileReader.onload = e => {
            changeSequence(e.target.result);
        }
        setInputType(["upload-seq", 1])
        console.log(inputType)
    }

    /**
     * Takes the user input and updates the internal state accordingly, including warning messages.
     * @param  {str} seq The user input
     */
    function changeSequence(seq) {
        setSample(-1);
        if (seq.length > WARNING_LENGTH) {
            setWarning(language.SEQ_WARNING);
        }
        if (seq.length > MAX_LENGTH) {
            setWarning(language.SEQ_ERROR);
            return setSequence(seq.substring(0, MAX_LENGTH));
        }
        if (seq.length <= WARNING_LENGTH) {
            setWarning(false);
        }
        setSequence(seq);
        setIsCorrectInput(true)
    }

    React.useEffect(() => {
        console.log(sequence)
        if (sequence.length <= 0) {
            setIsCorrectInput(false)
        } else {
            let tempSeq = sequence.split('\n')
            if (sequence.split('\n')[0][0] === '>') {
                tempSeq = tempSeq.slice(1, tempSeq.length).join("")
            } else {
                tempSeq = tempSeq.slice(0, tempSeq.length).join("")
            }
            const regex = /a|t|c|g|A|T|C|G/g
            tempSeq = tempSeq?.match(regex)?.join("")
            tempSeq?.length >= 21 ? setIsCorrectInput(true) : setIsCorrectInput(false)
            // console.log('tempSeq:', tempSeq)
        }
    }, [sequence])


    return (
        <ThemeProvider theme={MUItheme}>
            <div class={style.box} style={{ ...theme }}>

                <FormControl fullWidth style={{ marginTop: '10px', marginBottom: '15px' }}>
                    <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">Select plasmid sequence input type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="filled"
                        // value={inputType[0]}
                        defaultValue="paste-seq"
                        renderValue={() => { return allInputTypes[inputType[0]] }}
                        value={inputType[0]}
                        label="Select plasmid sequence input type"
                        onChange={(e) => {
                            if (e.target.value === "browse-database") {
                                // setInputType([e.target.value, 0])
                                navigate('/search')
                            } else {
                                setInputType([e.target.value, 0])
                            }
                        }}
                    >
                        <MenuItem value="paste-seq">Paste plasmid nucleotide sequence</MenuItem>
                        <MenuItem value="upload-seq">Upload plasmid nucleotide sequence file</MenuItem>
                        <MenuItem value="browse-database">Select a plasmid from PlasMapDB</MenuItem>
                    </Select>
                </FormControl>

                {inputType[0] === "browse-database" && <Link id="browse-database-link" to={'/search'} class={style.linkref}>
                    <div>{language.OPEN_LINK}<CustomLinkIcon /></div>
                </Link>}
                {/* <Or/> */}


                {(inputType[0] === "upload-seq" || inputType[1] === 1) && <FileUploader
                    children={
                        <div class={style.downloadBox} style={{ fontSize: '1.2em' }}><UploadFileIcon fontSize="large" />{"Drag & drop or click to upload .txt or .fasta nucleotide file (no protein sequences)"}</div>
                    }
                    types={['txt', 'fasta', 'fna', 'ffn', 'faa', 'frn', 'fa']}
                    handleChange={(e) => handleFile(e)}
                    name="file"
                    id="raised-button-file"
                    sx={{ marginTop: '15px', width: '10em' }}
                />}

                {(inputType[0] === "paste-seq" || inputType[1] === 1) && <><TextField
                    style={{ width: `100%`, marginTop: '15px', }}
                    id="outlined-multiline-static"
                    label={language.PASTE_SEQ}
                    multiline
                    rows={5}
                    InputProps={{
                        style: {
                            fontFamily: "Consolas, 'Courier New', Courier, monospace",
                            color: theme['--text'],
                            fontSize: "0.9rem",
                            // backgroundColor: theme['--background'],
                            backgroundColor: 'white'
                        }
                    }}
                    // maxRows={5}
                    value={sequence}
                    onChange={(e) => changeSequence(e.target.value)}
                />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography style={{ marginLeft: '10px', paddingRight: 'min(190px, 10%)', textAlign: 'left' }} sx={{ color: warning ? "red" : theme['--text'], fontSize: "1em", }}>{warning || language.SEQ_DETAILS}</Typography>
                        <Button
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                            onClick={() => {
                                setSequence("")
                                setSample(-1)
                                setIsCorrectInput(false)
                            }}
                        >Clear</Button>
                    </div></>}
                {/* <Or/> */}

                <Or />
                <Typography sx={{ color: theme['--text'], fontSize: "1.5em" }}>{language.EXAMPLES}</Typography>

                <div class={style.buttonholder} >
                    <div style={{ display: 'flex', gap: 3 }}>
                        {samplePlasmids.map((v, i) => {
                            return (
                                // <Tooltip title={v.name} arrow >
                                <>
                                    {/* <Tooltip title="Delete"> */}
                                    {/* <IconButton>
                                        <DeleteIcon />
                                    </IconButton> */}
                                    <ExampleButton
                                        btnColor={v.btnColor}
                                        key={`sample-${i}`}
                                        expressionSystem={v.expressionSystem}
                                        name={v.name}
                                        selected={sample === i}
                                        onClick={() => {
                                            setInputType(["paste-seq", 0])
                                            console.log("name: ", v.name)
                                            setPlasmidName(v.name);
                                            setSample(sample === i ? -1 : i);
                                            setSequence(sample !== i ? v.sequence : "");
                                            setIsCorrectInput(true)
                                        }}
                                    />
                                    {/* </Tooltip> */}

                                </>
                                // </Tooltip>
                            )
                        })}
                    </div>
                    <div style={{ display: 'flex', marginLeft: '0px' }}>
                        {<CircularProgress color="success" style={{ marginTop: '9px', marginRight: '5px', visibility: annotating ? 'visible' : 'hidden' }} />}
                        <div>
                            <Button
                                variant="contained"
                                disabled={!isCorrectInput}
                                // class={style.annotateButton}
                                sx={{ textTransform: 'none', width: '8.1rem', height: '2.75rem' }}
                                onClick={() => {
                                    // console.log("codonOptimization: ", codonOptimization)
                                    annotate(plasmidName, sequence)
                                }}
                            >
                                {`Annotate`}
                            </Button>
                        </div>
                    </div>
                </div>


            </div>
        </ThemeProvider>
    )
}

export default SequenceUpload
